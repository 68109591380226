<template>
  <div class="pacienteCard">
    <div class="foto">
      <img :draggable="false" class="img-fluid" :src="formataUrl()" />
    </div>
    <div class="nome">
      {{ info.razao_social }}
    </div>
    <div class="info">
      <div v-if="!info.cpf">
        <h4 class="text-danger py-3">
          Cadastro incompleto
        </h4>
      </div>
      <div v-if="info.cpf">
        <label>CPF</label>
        {{ info.cpf }}
      </div>
      <div v-if="info.telefones && info.telefones.length > 0">
        <label>Telefone</label>
        {{ info.telefones[0].telefone }}
      </div>
      <div v-if="info.dt_nascimento">
        <label>Nascimento</label>
        {{ info.dt_nascimento | moment("DD/MM/YYYY") }}
        ({{ info.idade }} anos)
      </div>
    </div>
  </div>
</template>

<script>
import FilesLib from "@/libs/FilesLib.js";

export default {
  props: {
    info: Object,
  },
  data() {
    return {
      token: this.$store.state.auth.user.token,
    };
  },
  methods: {
    formataUrl() {
      if (
        this.info.foto_entidade &&
        this.info.foto_entidade.indexOf("blob") >= 0
      ) {
        return this.info.foto_entidade;
      }
      return FilesLib.downlaodFile(this.info.foto_entidade, this.token);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/paciente.scss";
</style>
