<template>
  <div class="container-fluid">
    <barra-crud
      :onFiltrar="filtrar"
      :botaoNovo="true"
      :onNovo="onNovo"
      ref="barraCrud"
      :key="tipo"
      :minLength="minLengthSearch"
      filterAlphabet
      :char="filtros.alfabeto"
      @charClicked="filtros.alfabeto = $event"
      :filterAlphabetDisable="loading"
    />

    <b-tabs>
      <b-tab @click="filtros.status = 1">
        <template #title>
          Pacientes Ativos
        </template>
      </b-tab>
      <b-tab @click="filtros.status = 0"
        ><template #title> Pacientes Inativos</template></b-tab
      >
    </b-tabs>
    <div class="text-center p-5" v-if="loading">
      <b-spinner /><br />
      aguarde...
    </div>
    <div v-if="!loading && lista.length > 0">
      <div class="cardConteiner">
        <paciente-card
          v-for="paciente in lista"
          :key="paciente.id"
          :info="paciente"
          @click.native="openModal(paciente)"
        />
      </div>
      <b-pagination
        v-model="paginacao.pagina_atual"
        :total-rows="paginacao.total_registros"
        :per-page="paginacao.registros_por_pagina"
        @change="mudarPagina"
        :disabled="loading"
      >
      </b-pagination>
    </div>

    <div v-if="lista.length === 0 && !loading">
      <div class="text-center text-muted d-flex flex-column mt-5 p-2">
        <div class="justify-content-center py-2 ">
          <img
            width="150px"
            height="125px"
            :src="require('@/assets/img/undraw_No_data_re_kwbl.svg')"
            alt=""
          />
          <p class="mt-2">
            Nenhum paciente encontrado... Verifique os filtros ou cadastre um
            paciente clicando em
            <b-btn class="mr-1" size="sm" @click="onNovo" variant="primary">
              <i class="fas fa-plus"></i> Paciente
            </b-btn>
          </p>

          <hr />
          <p class="mt-2">
            Ou remova os filtros...
          </p>
          <b-btn
            class="mr-1"
            size="sm"
            @click="resetFilters"
            variant="secondary"
          >
            <i class="fas fa-times"></i> Remover Filtros
          </b-btn>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-paciente"
      size="lg"
      modal-class="modalMaiorFull"
      body-class="modalNoPadding"
      hide-footer
      hide-header
      @close="closeModal"
      @carregar="carregar"
    >
      <paciente-ficha
    
        :paciente_id="pacienteSelecionado?pacienteSelecionado.id:null"
        @close="closeModal"
        @carregar="carregar"
      />
    </b-modal>
  </div>
</template>

<script>
// import EntidadesTable from "../../components/Entidades/EntidadesTable.vue";
// import EntidadesForm from "../../components/Entidades/EntidadesForm.vue";
import BarraCrud from "../components/common/BarraCrud.vue";
import PacienteCard from "../components/Entidades/Paciente/PacienteCard.vue";
import EntidadesLib from "../libs/EntidadesLib.js";
import PacienteFicha from "../components/Entidades/Paciente/PacienteFicha.vue";

export default {
  props: {
    tipo: String,
  },
  components: {
    BarraCrud,
    PacienteCard,
    PacienteFicha,
    // EntidadesTable,
    // EntidadesForm,
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      pacienteSelecionado: null,
      salvando: false,
      filtros: {
        alfabeto: "",
        geral: "",
        status: 1,
      },
      minLengthSearch: 3,
      loading: false,
      lista: [],
      paginacao: {
        registros_por_pagina: 12,
        pagina_atual: 1,
        total_registros: 0,
      },
      Entidade: {
        id: "",
        razao_social: "",
        fantasia: "",
        crm: "",
        ie: "",
        cpf: "",
        email: "",
        convenio_id: "",
        status: 1,
        tipo_pessoa: "PF",
        telefones: [],
        enderecos: [],
        configuracoes: {
          especialidades: [],
          contasBancarias: [],
          convenio: {
            limiteQtdCompras: null,
            limiteValorCompras: null,
            diaFechamento: null,
          },
        },
        exames: [],
        empresa_id: null,
        user_id: null,
        convenio: 0,
        fornecedor: 0,
        laboratorio: 0,
        medico: 0,
      },
    };
  },
  computed: {
    // listaFiltrada() {
    //   return this.filtro
    //     ? this.lista.filter(
    //         (p) =>
    //           p.razao_social &&
    //           p.razao_social.toLowerCase().indexOf(this.filtro) !== -1
    //       )
    //     : this.lista;
    // },
  },
  methods: {
    openModal(paciente) {
      // console.log("tentou abrir modal");
      this.pacienteSelecionado = paciente;
      this.$bvModal.show("modal-paciente");
      paciente;
    },
    closeModal() {
      this.$bvModal.hide("modal-paciente");
      this.carregar();
    },
    setSalvando(val) {
      this.salvando = val;
    },
    filtrar(filtro) {
      this.filtros.geral = filtro;
    },
    async onNovo() {
      this.Entidade = {
        id: "",
        razao_social: "",
        fantasia: "",
        crm: "",
        ie: "",
        cpf: "",
        email: "",
        enderecos: [],
        convenio_id: "",
        telefones: [],
        tipo: "PF",
        status: 1,
        // tipo_pessoa: "PF",
        configuracoes: {
          especialidades: [],
          contasBancarias: [],
          convenio: {
            limiteQtdCompras: null,
            limiteValorCompras: null,
            diaFechamento: null,
          },
        },
        convenio: this.tipo == "convenio" ? 1 : 0,
        fornecedor: this.tipo == "fornecedor" ? 1 : 0,
        laboratorio: this.tipo == "laboratorio" ? 1 : 0,
        medico: this.tipo == "medico" ? 1 : 0,
        paciente: this.tipo == "paciente" ? 1 : 0,
      };

      // console.log(this.Entidade);
      this.openModal(this.Entidade);
    },
    mudarPagina(e) {
      this.paginacao.pagina_atual = e;
      this.$nextTick();
      this.carregar();
    },
    resetFilters() {
      this.filtros = {
        alfabeto: "",
        geral: "",
        status: 1,
      };
    },
    async carregar() {
      this.loading = true;

      await this.$nextTick();

      try {
        // console.log(this.paginacao);
        let result = await EntidadesLib.getResumido(
          null,
          this.paginacao,
           this.filtros,
          ['paciente']
         
        );
        this.lista = result.entidades;
        // console.log(result.pag);
        this.paginacao = result.pagination;
        if (this.pacienteSelecionado) {
          this.pacienteSelecionado = this.lista.find(
            (x) => x.id == this.pacienteSelecionado.id
          );
        }
        this.$forceUpdate();
      } catch (error) {
        console.warn(error);
        this.$swal.fire({
          title: `Erro ao buscar ${this.tipo}!\n + ${error.message}`,
          icon: "error",
          toast: true,
          timer: 2500,
          position: "top-end",
          showConfirmButton: false,
        });
      }

      this.loading = false;
    },
  },
  watch: {
    // filtros: {
    //   deep: true,
    //   async handler() {
    //     this.paginacao.pagina_atual = 1

    //     await this.carregar();
    //   },
    // },
    "filtros.status": {
      async handler() {
        this.paginacao.pagina_atual = 1;

        await this.carregar();
      },
    },
    "filtros.alfabeto": {
      async handler() {
        this.paginacao.pagina_atual = 1;

        await this.carregar();
      },
    },
    "filtros.geral": {
      async handler(newV, oldV) {
        if (
          newV.trim().length >= this.minLengthSearch &&
          oldV.trim() < this.minLengthSearch
        ) {
          if (this.filtros.alfabeto.trim()) this.filtros.alfabeto = "";
        }
        this.paginacao.pagina_atual = 1;

        await this.carregar();
      },
    },
  },
};
</script>

<style></style>
